<script>
import SeoMixin from '~/sites/virtshop/mixins/SeoMixin'
export default {
  extends: SeoMixin,
  methods: {
    getFavicons() {
      return [{ rel: 'icon', type: 'image/x-icon', href: '/favicon/intimfitness/favicon.ico' }]
    },
  },
}
</script>
